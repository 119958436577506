.api-box {
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    border-radius: .5rem;
    cursor: pointer;
    position: relative;

    .api-type {
        padding: .7rem 1rem;

        .api-badge {
            font-size: .75rem;
            padding: .25rem .5rem;
            border-radius: .25rem;
            background-color: green;
            color: white;
        }
    }

    .api-url {
        padding: .7rem 1rem;
        padding-left: 0;
        display: flex;
        align-items: center;
        word-break: break-all;

        .copy-icon {
            display: none;
        }

    }

    &:hover {
        background-color: #eee;
        .api-url {
    
            .copy-icon {
                display: initial;
            }
        }
    }

    &:active {
        background-color: #ddd;
    }
}

.copy-status {
    position: absolute;
    top: -.75rem;
    left: 25%;
    padding-top: 6px!important;
    display: none;

    &.show {
        display: initial;
    }
}

.api-code {
    background-color: #F6F6F6;
    padding: .75rem;
    border-radius: .5rem;
    font-size: .75rem!important;
}

.text-disabled {
    color: rgba(#2b2b2b, .75)!important;
}

.api-table {
    code {
        font-size: 15px;
    }
}