.btnBg {
    padding: 0!important;
    width: 2.5rem!important;
    height: 2.5rem!important;
    font-size: 1.5rem!important;

    display: flex!important;
    align-items: center!important;
    justify-content: center!important;

    cursor: pointer!important;
    border-radius: 100%!important;

    background: rgba(256,256,256,0.25)!important;
    color: white!important;

    &:hover {
        background: rgba(256,256,256,0.15)!important;
    }
}

.cardIcon {
    position: absolute;
    right: -40px;
    bottom: -40px;
    opacity: 0.2;
}

.dot {
    border-radius: 100%;

    border: 5.5px solid #f3cbd0;
    background:#dc3545;

    width: 1.25rem;
    height: 1.25rem;
    margin-right: 6px;

    &.online {
        border: 5.5px solid #b6eec3;
        background:#28a745;
    }
}

@media only screen and (max-width: 992px) {
    .tableResponsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}