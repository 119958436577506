.tabcard {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(8, 21, 66, .05);
    padding: 0 2rem;

    overflow-x: auto;
    ul{
        li{
            padding: 1rem .5rem .8rem .5rem;
            margin: 0 1rem;
            white-space: nowrap;
            text-overflow: clip;
            font-weight: 500;
            color: rgba(0, 0, 0, .4);
            cursor: pointer;

            &:first-of-type {
                margin-left: 0;
            }

            &:hover {
                color: #007bff;
            }

            &.active {
                color: #007bff;
                border-bottom: 2px solid #007bff;
            }
        }
    }
}