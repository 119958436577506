.loadingOverlay {
    background-color: rgba(0,0,0,0.5);
    z-index: 1501;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loadingCard {
    position: fixed;
    z-index: 1503;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loadingSpinner {
        width: 5rem;
        height: 5rem;
        border: 0.5rem solid white;
        border-right-color: transparent;
    }
}